import { Button, Modal, Table, Tooltip } from 'antd';
import ModalCategoryForm from 'components/ModalCategoryForm';
import { useFormatMessage } from 'hooks';
import paths from 'pages/Router/paths';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import { log } from 'utils';
// import Table from 'components/Table';
import {
  createCategory,
  deleteCategory,
  fetchCategories,
  modifyCategory,
} from 'state/actions/categories';
import { regexInput } from 'utils/regex';
import './Categories.scss';

const Categories = () => {
  const { siteId } = useParams();

  const {
    categoriesList,

    loading,
    deleted,
    userData,
    updated,
    role,
  } = useSelector(
    (state) => ({
      categoriesList: state.categories.list,

      loading: state.categories.loading,
      deleted: state.categories.deleted,
      userData: state.auth.userData,
      updated: state.categories.updated,
      role: state.auth.userData.role,
    }),
    shallowEqual
  );

  if (categoriesList) {
    log(categoriesList, '<<< categories list');
  }

  const [deleteModal, setDeleteModal] = useState({
    categoryId: null,
    isOpen: false,
  });

  const defaultCategory = {
    name: '',
    description: '',
  };

  const dispatch = useDispatch();

  const [search, setSearch] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const [visible, setVisible] = useState(false);
  const [category, setCategory] = useState(defaultCategory);
  const [error, setError] = useState(null);

  const uid = userData.id;

  useEffect(() => {
    dispatch(fetchCategories({ siteId }));
  }, [siteId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    log(updated);
    if (updated && !loading) {
      dispatch(fetchCategories({ siteId }));
      setVisible(false);
    }
  }, [updated, loading]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (deleted && !loading) {
      setDeleteModal((prevState) => ({
        categoryId: null,
        isOpen: !prevState.isOpen,
      }));
      dispatch(fetchCategories({ siteId }));
    }
  }, [deleted, loading]); // eslint-disable-line react-hooks/exhaustive-deps

  const redirect = !role && <Redirect to={paths.ROOT} />;

  const onCreate = (values) => {
    const action = isEditing ? modifyCategory : createCategory;

    dispatch(action({ uid, ...values, siteId }));
  };

  const onRemoveButtonClickHandler = (categoryId) => {
    setDeleteModal((prevState) => ({
      categoryId,
      isOpen: !prevState.isOpen,
    }));
  };

  const onCloseModalHandler = () => {
    setDeleteModal({ categoryId: null, isOpen: false });
  };

  const onDeleteUserHandler = () => {
    dispatch(deleteCategory(deleteModal.categoryId));
  };

  const onNewCategoryHandler = () => {
    setCategory(defaultCategory);
    setIsEditing(false);
    setVisible(true);
  };

  const columns = [
    {
      title: useFormatMessage('Categories.name'),
      dataIndex: 'name',
      sorter: {
        compare: (a, b) => a.name - b.name,
        multiple: 3,
      },
      width: '40%',
    },
    {
      title: useFormatMessage('Categories.description'),
      dataIndex: 'description',
      sorter: {
        compare: (a, b) => a.description - b.description,
        multiple: 2,
      },
      width: '40%',
    },
    {
      title: 'Option',
      dataIndex: 'option',

      render: (text, record) => (
        <div className="categories__actions">
          {role && (
            <div className="buttons is-right">
              <Tooltip title="Edit Poi Category">
                <button
                  data-tooltip="Edit"
                  onClick={() => {
                    setCategory(record);
                    setVisible(true);
                    setIsEditing(true);
                  }}
                  className="btn btn--edit"
                >
                  <i className="feather-edit" />
                </button>
              </Tooltip>

              <Tooltip title="Delete Poi Category">
                <button
                  data-tooltip="Delete"
                  type="button"
                  className="btn btn--delete"
                  onClick={() => onRemoveButtonClickHandler(record._id)}
                >
                  <i className="feather-trash-2" />
                </button>
              </Tooltip>
            </div>
          )}
        </div>
      ),
    },
  ];

  const handleSearch = (e) => {
    setError(null);
    if (!regexInput(e.target.value) && e.target.value) {
      setError('Search cannot using special character');
    }

    setSearch(e.target.value);
  };

  const data = search
    ? categoriesList.filter((el) => {
        const clonedElem = { ...el };
        return clonedElem.name.toLowerCase().includes(search.toLowerCase());
      })
    : categoriesList;

  const confirmMessage = useFormatMessage('Categories.confirm');

  const permDeleteMessage = useFormatMessage('Categories.permDelete');

  return (
    <section className="categories">
      {redirect}
      {deleteModal.isOpen && (
        <Modal
          title={confirmMessage}
          visible={deleteModal.isOpen}
          onOk={onDeleteUserHandler}
          onCancel={onCloseModalHandler}
        >
          {permDeleteMessage}
        </Modal>
      )}

      {visible && (
        <ModalCategoryForm
          visible={true}
          onCancel={() => {
            setVisible(false);
          }}
          onCreate={onCreate}
          category={category}
          setCategory={setCategory}
          isEditing={isEditing}
        />
      )}
      <div className="categories__nav">
        <div className="categories__search-wrapper">
          <div className="categories__search">
            <i className="feather-search"></i>
            <input
              type="text"
              placeholder="Search..."
              value={search}
              onChange={handleSearch}
            />
          </div>
          {error && <div className="search-error">{error}</div>}
        </div>

        <Button
          type="primary"
          className="button-primary"
          onClick={onNewCategoryHandler}
        >
          {useFormatMessage('Categories.newCategory')}
        </Button>
      </div>

      <div className="categories__table">
        {loading ? (
          <ClipLoader />
        ) : (
          <Table columns={columns} dataSource={data} rowKey="_id" />
        )}
        {error && 'Show error'}
      </div>
    </section>
  );
};

export default Categories;
