import InformationForm from 'components/InformationForm';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { fetchInformation } from 'state/actions/information';
import {
  createInformation,
  modifyInformation,
  setInformation,
} from 'state/actions/information';

const Informations = ({ history }) => {
  const { id, siteId, siteName } = useParams();
  const dispatch = useDispatch();

  const { information, error, informationList } = useSelector((state) => ({
    information: state.information.information,
    error: state.information.error,
    informationList: state.information.list,
  }));

  useEffect(() => {
    if (id) {
      const informationFetched = informationList.find(
        (fetchedInformation) => fetchedInformation._id === id
      );
      if (informationFetched) {
        dispatch(setInformation({ ...information, ...informationFetched }));
      } else if (information._id === id) {
        dispatch(setInformation(information));
      } else {
        dispatch(fetchInformation(id));
      }
    }
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const editInformationMessage = 'Edit Information';

  const newInformationMessage = 'Add New Information';

  const isEditing = !!id;

  const informationForm =
    !information && id ? (
      <ClipLoader />
    ) : (
      <InformationForm
        history={history}
        id={id}
        error={error}
        action={isEditing ? modifyInformation : createInformation}
      />
    );

  return (
    <div className="media">
      <div className="media-header">
        <h1>{isEditing ? editInformationMessage : newInformationMessage}</h1>
        <Link
          to={`/${siteId}/information/${siteName}`}
          className="button-secondary"
        >
          <i className="feather-arrow-left" />
          Back
        </Link>
      </div>
      <div className="media-content">{informationForm}</div>
    </div>
  );
};

export default Informations;
